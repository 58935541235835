import UMFB_Logo from "../UMFB-LOGO-MAIN.png";

const Header=()=>{
    return(
        <div>
            <center>
                <img src={UMFB_Logo} id="UMFB-Logo"/>
                <h1>ACCOUNT OPENING APPLICATION FORM</h1>
            </center>
        </div>
    );
}

export default Header;